import { myEvents } from ".";

import {
  getAllEventTicketsService,
  getOneEventService,
  payAnEventService,
  rsvpAnEventService,
} from "./services";
export const getOneEventAction = (itemId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEvents.setIsLoading(true));
      const res = await getOneEventService(itemId);
      console.log(">>>>!!!>>>::)) ", res?.status, res);
      if (res?.status === "Success") {
        dispatch(myEvents.setSelected(res?.data));
        dispatch(myEvents.setIsLoading(false));
      }
      dispatch(myEvents.setIsLoading(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const rsvpAnEventAction = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEvents.setIsLoading(true));
      const res = await rsvpAnEventService(data);
      console.log(">>>>!!!>>>::)) ", res?.response?.data);
      // if (res?.status === "Success") {
      dispatch(myEvents.setRsvpEvent(res?.data || res?.response?.data));
      dispatch(myEvents.setIsLoading(false));
      // }
      dispatch(myEvents.setIsLoading(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const payAnEventAction = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEvents.setIsLoading(true));
      const res = await payAnEventService(data);
      console.log(">>>>!!!>>>::)) ", res?.data);
      // if (res?.status === "Success") {
      dispatch(myEvents.setPaidEvent(res?.data || res?.response?.data));
      dispatch(myEvents.setIsLoading(false));
      // }
      dispatch(myEvents.setIsLoading(false));

      if (res?.data?.url) {
        window.location.href = res?.data?.url;
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllEventTicketsAction = (itemId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEvents.setIsLoading(true));
      const res = await getAllEventTicketsService(itemId);
      console.log(">>>>!!!>>>::)) ", res?.status, res);
      if (res?.status === "Success") {
        dispatch(myEvents.setEventTickets(res?.data));
        dispatch(myEvents.setIsLoading(false));
      }
      dispatch(myEvents.setIsLoading(false));
    } catch (err) {
      console.log(err);
    }
  };
};
