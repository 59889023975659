import React from "react";

interface TicketProps {
  isFree?: boolean;
  name?: string;
  price?: number; // Optional, since it's irrelevant if `isFree` is true
}

const Ticket: React.FC<TicketProps> = ({ isFree, name, price }) => {
  return (
    <div className="relative bg-teal-800 text-white w-48 h-20 rounded-lg shadow-lg">
      <div className="absolute inset-0 bg-teal-800 rounded-lg">
        <div className="flex flex-col items-center justify-center h-full">
          <p>{name}</p>
          <p className=" text-xl font-bold">${price}</p>
        </div>
      </div>
      <div className="absolute top-1/2 -left-5 transform -translate-y-1/2 w-10 h-10 bg-white rounded-full"></div>
      <div className="absolute top-1/2 -right-5 transform -translate-y-1/2 w-10 h-10 bg-white rounded-full"></div>
      <div className="absolute top-4 bottom-4 right-12 border-r-2 border-dotted border-white"></div>
    </div>
  );
};

export default Ticket;
