import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "events",
  initialState: {
    isLoading: false,
    all: null,
    selected: null,
    new: null,
    updated: null,
    rsvpEvent: null,
    paidEvent: null,
    tickets: [],
    cart: null,
    selectedTicket: {},
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },

    setNew(state, action) {
      state.new = action.payload;
    },
    setUpdated(state, action) {
      state.updated = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setRsvpEvent(state, action) {
      state.rsvpEvent = action.payload;
    },

    setPaidEvent(state, action) {
      state.paidEvent = action.payload;
    },
    setEventTickets(state, action) {
      state.tickets = action.payload;
    },
    setCart(state, action) {
      state.cart = action.payload;
    },
    setSelectedTicket(state, action) {
      state.selectedTicket = action.payload;
    },
  },
});

export const myEvents = mySlice.actions;

export default mySlice.reducer;
