import React from "react";
import { FaCheckCircle } from "react-icons/fa";

interface SuccessProps {
  message?: string;
  isPaid?: boolean;
  onDismiss?: () => void;
}

const SuccessRSVP: React.FC<SuccessProps> = ({
  isPaid,
  message = `${
    isPaid ? "Payment was successful!" : "Your RSVP was successful!"
  } please check your email`,
  onDismiss,
}) => {
  return (
    <div className="flex flex-col items-center justify-center bg-white p-6 w-full max-w-md mx-auto mt-10">
      <FaCheckCircle className="text-green-500 text-5xl mb-4" />
      <h2 className="text-2xl font-bold text-gray-800 mb-2">Success!</h2>
      <p className="text-gray-600 mb-4 text-center">{message}</p>
      {onDismiss && (
        <button
          onClick={onDismiss}
          className="px-4 py-2 bg-green-500 text-white font-medium rounded-md hover:bg-green-600 focus:outline-none focus:ring focus:ring-green-300"
        >
          Dismiss
        </button>
      )}
    </div>
  );
};

export default SuccessRSVP;
