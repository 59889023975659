import React from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

interface ErrorCardProps {
  status: string;
  message: string;
  onRetry?: () => void;
  onClose?: () => void;
}

const ErrorCard: React.FC<ErrorCardProps> = ({
  status,
  message,
  onRetry,
  onClose,
}) => {
  return (
    <div className="relative flex flex-col bg-red-50 border border-red-200 rounded-lg mb-5 p-2 w-full mx-auto mt-10">
      {onClose && (
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-red-500 hover:text-red-700 focus:outline-none"
        >
          <IoClose size={20} />
        </button>
      )}
      <h2 className="flex items-center gap-x-2 text-sm font-bold text-red-700 mb-2">
        <FaExclamationTriangle className="text-red-500" />
        {status}
      </h2>
      <p className="text-gray-700 px-2">{message}</p>
      {onRetry && (
        <button
          onClick={onRetry}
          className="px-4 py-2 bg-red-500 text-white font-medium rounded-md hover:bg-red-600 focus:outline-none focus:ring focus:ring-red-300"
        >
          Retry
        </button>
      )}
    </div>
  );
};

export default ErrorCard;
