import { myDashboard } from ".";
import { getMinimalMetricsService } from "./services";

export const getMinimalMetrics = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboard.setIsLoading(true));
      const res = await getMinimalMetricsService(token);
      console.log("Resp", res?.status, res);
      if (res?.status?.toLowerCase() === "success") {
        console.log(";;;;;;;", res?.data)
        dispatch(myDashboard.setMinimalMetrics(res?.data));
        dispatch(myDashboard.setIsLoading(false));
        return true;
      }

      dispatch(myDashboard.setIsLoading(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};
