import { GoClock } from "react-icons/go";
import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllEventTicketsAction,
  getOneEventAction,
  payAnEventAction,
} from "../redux/events/actions";
import logo from "../assets/logo.png";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import TicketBig from "../components/TicketBig";
import { IoLocationOutline, IoShareSocial } from "react-icons/io5";
import React from "react";
import Ticket from "../components/Ticket";
import TermsAndConditionsModal from "../pages/TermsAndConditions";
import { TicketNew } from "../components/TicketNew";
import ErrorCard from "../components/ErrorCard";
import { myEvents } from "../redux/events";

const handleDownload = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    // Redirect to Google Play Store
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.outgroupinc.outapp&hl=en";
  } else if (/iPad|iPhone|iPod/.test(userAgent)) {
    // Redirect to Apple App Store
    window.location.href = "https://apps.apple.com/rw/app/out-app/id6482999195";
  } else {
    // Fallback for other devices or unsupported platforms
    alert("This app is only available for Android and iOS devices.");
  }
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const CartPage = () => {
  const eventId: string = useParams()?.id || "";
  const events = useSelector((state: any) => state.events);
  const dispatch = useDispatch();
  const query = useQuery();
  const queryIsTermsAndCondition = query.get("isTermsAndCondition");
  const queryIsModalOpen = query.get("isModalOpen");

  const [isSticky, setIsSticky] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(queryIsModalOpen || false);
  const [accpetTnC, setAcceptTnC] = useState(false);
  const [isTermsAndCondition, setIsTermsAndCondition] = useState(
    queryIsTermsAndCondition || false
  );

  const closeModal = () => {
    setIsModalOpen(false);
    // navigate("/");
  };

  const toggleTnC = () => {
    setAcceptTnC(!accpetTnC);
  };

  const openModal = (termsAndCondition: boolean) => {
    setIsTermsAndCondition(termsAndCondition);
    setIsModalOpen(true);
  };

  useEffect(() => {
    getOneEventAction(eventId)(dispatch);
    getAllEventTicketsAction(eventId)(dispatch);
  }, [dispatch, eventId]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // dispatch(myEvents.setIsLoading(true));
    console.log("#1... paying...");
    await payAnEventAction(events?.cart)(dispatch);

    // console.log("Form Data:", events?.cart);
  };

  return (
    <div
      className={`xh-screen bg-fixed bg-cover bg-center bg-opacity-40`}
      style={{
        backgroundImage: `url('${
          isSticky && events?.selected?.backdropImage
        }')`,
      }}
    >
      <header className="p-4 sticky top-0 z-10 xbg-white bg-white/30 backdrop-blur-lg">
        <div className="container flex justify-between items-center  mx-auto">
          <img src={logo} className="h-10 md:h-15" alt="logo" />
          <nav className="flex gap-6 text-black ">
            <a
              href="/#"
              className="hover:text-gray-800 font-medium text-[#209bc5]"
              onClick={(e) => {
                e.preventDefault();
                handleDownload();
              }}
            >
              Download the App
            </a>
          </nav>
        </div>
      </header>
      <div
        className={`container mx-auto transition-colors duration-500 ${
          isSticky ? "bg-transparent" : "bg-[#fffdfd]"
        } min-h-screen`}
      >
        {/* Event Banner */}
        <div className="container p-6 md:p-10">
          {/* Event Image and Title */}
          <div
            className="relative bg-cover rounded-lg overflow-hidden w-full h-[556px]"
            style={{
              backgroundImage: `url(${events?.selected?.backdropImage})`,
            }}
          >
            <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col justify-end p-4 text-white">
              <h1 className="text-2xl md:text-3xl font-bold">
                {events?.selected?.title}
              </h1>
              {/* <p className="text-lg">Event by: CMA Events</p> */}
            </div>
            <button
              title="share the event"
              className="absolute top-4 right-4 text-white p-2 rounded-full"
            >
              <IoShareSocial className="h-7 w-7" />
            </button>
          </div>

          {/* Content Section */}
          <div className="mt-8 grid md:grid-cols-2 gap-6">
            {/* Event Details */}
            <div>
              <h2 className="text-xl font-semibold mb-4">Event Details</h2>
              <div className="bg-[#e7f2f8] rounded-md p-4 space-y-4 border-2 border-[#5eb2ec] border-opacity-45">
                <div className="flex items-center gap-x-5">
                  <GoClock />
                  <p className="uppercase">
                    {new Date(events?.selected?.time).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    })}
                  </p>
                </div>
                <div className="flex items-center gap-x-5">
                  <IoLocationOutline />
                  {/* <a
                    href={googleMapsUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-400"
                  > */}
                  <p>{events?.selected?.location?.address}</p>
                  {/* </a> */}
                </div>
                {/* <p>
                  <strong>💬</strong>{" "}
                  <a href="#" className="text-blue-500 hover:underline">
                    Join Event Chat
                  </a>
                </p>
                <p>
                  <strong>👥</strong> 274+ People Confirmed attending
                </p> */}
              </div>

              <div className="pb-7">
                <h2 className="mt-5 text-xl font-semibold mb-6">
                  Additional Information
                </h2>
                <p className="text-gray-400">
                  {events?.selected?.description ||
                    "No additional infomation provided"}
                </p>
              </div>
            </div>

            {/* Billing Details */}

            <div>
              <h2 className="text-xl font-semibold mb-4">Billing Details</h2>
              <div className="grid grid-cols-2 gap-4">
                <input
                  className="border border-[#95acb6] bg-[#e6ebed] p-2 rounded-md"
                  type="text"
                  placeholder="First Name"
                  defaultValue={events?.cart?.firstName}
                  value={events?.cart?.firstName}
                  disabled
                />
                <input
                  className="border border-[#95acb6] bg-[#e6ebed] p-2 rounded-md"
                  type="text"
                  placeholder="Last Name"
                  defaultValue={events?.cart?.lastName}
                  value={events?.cart?.lastName}
                  disabled
                />
                {/* <input
                  className="border border-[#95acb6] bg-[#e6ebed] p-2 rounded-md"
                  type="text"
                  placeholder="Phone Number"
                  defaultValue="+250 783 840 277"
                />
                <input
                  className="border border-[#95acb6] bg-[#e6ebed] p-2 rounded-md"
                  type="text"
                  placeholder="Address"
                  defaultValue="Watergates, Berlin, Germany"
                /> */}
                <input
                  className="border border-[#95acb6] bg-[#e6ebed] p-2 rounded-md"
                  type="email"
                  placeholder="Email Address"
                  defaultValue={events?.cart?.email}
                  value={events?.cart?.email}
                  disabled
                />
                {/* <button
                  type="button"
                  className="px-6 py-2 bg-[#1080b9] text-white rounded-full hover:bg-blue-600"
                >
                  Verify your Email
                </button> */}
              </div>
            </div>
          </div>

          {/* Ticket & Payment Details */}
          <div className="mt-12 grid md:grid-cols-2 gap-6">
            {/* Ticket Details */}
            <div>
              <h2 className="text-xl font-semibold mb-4">Ticket Details</h2>
              <p className="text-gray-600 mb-4">
                Lorem ipsum dolor sit amet consectetur. Mattis sed viverra donec
                bibendum et. Ut tortor turpis volutpat mi cras fames a quam.
              </p>

              <Ticket
                name={events.selectedTicket?.name}
                price={events.selectedTicket?.price}
              />

              {/* 
              <TicketBig
                eventName="New Year Fest"
                ticketId="9379"
                ticketPrice="$199.99"
                qrCodeUrl="https://via.placeholder.com/150" // Replace with your QR code URL
              /> */}

              {/* <TicketNew /> */}
            </div>

            {/* Payment Details */}
            <div>
              <h2 className="text-xl font-semibold mb-4">Payment Details</h2>
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <img
                      src="https://via.placeholder.com/40"
                      alt="QR Code"
                      className="mr-2"
                    />
                    <p>{events.selectedTicket?.name}</p>
                  </div>
                  <p className="uppercase">{`${events.selectedTicket?.currency} ${events.selectedTicket?.price}`}</p>
                </div>
              </div>
              <hr className="my-4" />
              <div className="flex justify-between">
                <p>Total:</p>
                <p className="font-semibold uppercase">{`${events.selectedTicket?.currency} ${events.selectedTicket?.price}`}</p>
              </div>
              <div className="mt-4">
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    checked={accpetTnC}
                    onClick={() => toggleTnC()}
                  />
                  <span>
                    By Clicking this you’re agreeing to our{" "}
                    <span
                      className="text-blue-500"
                      onClick={() => openModal(true)}
                    >
                      Terms & Policies
                    </span>
                  </span>
                </label>
                <button
                  disabled={!accpetTnC}
                  onClick={handleSubmit}
                  className={`mt-4 px-6 py-2 ${
                    !accpetTnC ? "bg-blue-300" : "bg-blue-500"
                  } text-white rounded-md ${
                    !accpetTnC ? "" : "hover:bg-blue-600"
                  }`}
                >
                  {events.isLoading
                    ? "Redirecting, please wait..."
                    : "Proceed the Payment"}
                </button>
              </div>
              {events.paidEvent?.status && (
                <ErrorCard
                  message={events.paidEvent?.message}
                  status={events.paidEvent?.status}
                  onClose={() => {
                    dispatch(myEvents.setPaidEvent(null));
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <TermsAndConditionsModal
        isOpen={isModalOpen}
        onClose={closeModal}
        isTermsAndCondition={isTermsAndCondition}
      />
    </div>
  );
};

export default CartPage;
