import { myAuth } from ".";
import { loginService } from "./services";

export const loginAction = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myAuth.setIsLoading(true));
      const res = await loginService(data);
      console.log("Resp", res?.status, res);
      if (res?.status?.toLowerCase() === "success") {
        console.log(";;;;;;;", res?.data);
        localStorage.setItem("outAuthUser", JSON.stringify(res?.data));
        dispatch(myAuth.setUser(res?.data));
        dispatch(myAuth.setIsLoading(false));
        return true;
      }

      dispatch(myAuth.setIsLoading(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};
